import { CurrentUser } from '@redwoodjs/auth'
import { useMutation } from '@redwoodjs/web'

import { QUERY as UserDataGridQuery } from 'src/components/MemberManagementCell/MemberManagementCell'
import { useAuth } from 'web/src/Providers'

const DEACTIVATE_MEMBER = gql`
  mutation DeactivateMemberMutation($id: Int!) {
    deactivateMembership(id: $id) {
      id
    }
  }
`

export const useDeactivateMember = () => {
  const { currentUser }: { currentUser: CurrentUser } = useAuth()

  const [deactivateMemberMutation] = useMutation(DEACTIVATE_MEMBER, {
    onCompleted: () => {},
    refetchQueries: [
      {
        query: UserDataGridQuery,
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  const deactivateMember = async (args) => {
    for (const item of args.data) {
      await deactivateMemberMutation({
        variables: {
          id: item.id,
        },
      })
    }
  }

  return { deactivateMember }
}
