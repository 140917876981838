import { isEmailValid } from 'api/src/common/utils'
import {
  CreateMembershipForUserIdInput,
  CreateUserWithMembershipMutation,
  CreateUserWithMembershipMutationVariables,
} from 'types/graphql'

import { CurrentUser } from '@redwoodjs/auth'
import { useMutation } from '@redwoodjs/web'

import { ItemDialogData } from 'src/components/MemberManagement/UserDataTypes'
import { QUERY as UserDataGridQuery } from 'src/components/MemberManagementCell/MemberManagementCell'
import { useAuth } from 'web/src/Providers'

const CREATE_USER_WITH_MEMBERSHIP = gql`
  mutation CreateUserWithMembershipMutation(
    $inviteUser: Boolean
    $user: CreateUserInput!
    $membership: CreateMembershipForUserIdInput!
    $membershipGroupIds: [Int!]!
    $memberPositions: [Int!]
  ) {
    createUserWithMembership(
      inviteUser: $inviteUser
      user: $user
      membership: $membership
      membershipGroupIds: $membershipGroupIds
      memberPositions: $memberPositions
    ) {
      user {
        id
      }
      membership {
        id
      }
    }
  }
`

export const useUpsertUserWithMembership = () => {
  const { currentUser }: { currentUser: CurrentUser } = useAuth()

  const [createUserWithMembershipMutation] = useMutation<
    CreateUserWithMembershipMutation,
    CreateUserWithMembershipMutationVariables
  >(CREATE_USER_WITH_MEMBERSHIP, {
    awaitRefetchQueries: true,
    // FIXME:
    //  refetch shouldn't be required, but is for the moment.
    //  First update doesn't seems to optimistically update the grid,
    //  but the second update does.
    refetchQueries: [
      {
        query: UserDataGridQuery,
        fetchPolicy: 'network-only',
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  const upsertUserWithMembership = async (args: { data: ItemDialogData }) => {
    const { data: dialogData } = args

    const avatarStorageObjectId = dialogData.avatarStorageObjectId
      ? Number(dialogData.avatarStorageObjectId)
      : null

    const user = {
      id: dialogData.userId || undefined,
      email: dialogData.email,
      phone: dialogData.phone,
      name: dialogData.name,
      avatarStorageObjectId: avatarStorageObjectId,
      position: dialogData.position,
    }

    const isSuperAdmin = currentUser.roles.includes('SUPERADMIN')

    const membership = {
      role:
        dialogData.role === 'OWNER' && !isSuperAdmin
          ? undefined
          : dialogData.role,
      clientId: currentUser.membershipData.clientId,
      membershipType: 'CLIENT',
      position: dialogData.position,
      personalInfoData: {
        aboutMe: dialogData.aboutme ?? '',
        likes: dialogData.likes ?? '',
        dislikes: dialogData.dislikes ?? '',
        hobbies: dialogData.hobbies ?? '',
      },
      reportsToId: dialogData.reportsTo ? dialogData.reportsTo : null,
    } as CreateMembershipForUserIdInput

    const membershipGroupIds = dialogData?.membershipGroups ?? []

    // Validate email.
    if (!isEmailValid(dialogData.email)) {
      throw new Error('Invalid email address')
    }

    const memberPositions = dialogData?.memberPosition ?? []

    const result = await createUserWithMembershipMutation({
      variables: {
        inviteUser: dialogData.inviteUser,
        user,
        membership,
        membershipGroupIds,
        memberPositions,
      },
    })

    return result
  }

  return { upsertUserWithMembership }
}
