import { FC, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'

import { UserData } from 'src/components/MemberManagement/MemberManagement.mapper'
import Modal from 'src/components/Modal/Modal'

interface InviteModalProps {
  isOpen: boolean
  users: UserData[]
  onClose(): void
}

export interface InviteMessage {
  id: number
  type: 'success' | 'error' | 'waiting'
  message: string
}

const InviteModal: FC<InviteModalProps> = ({ isOpen, users, onClose }) => {
  const INVITE_USER_MUTATION = gql`
    mutation InviteUserMutation($id: Int!) {
      inviteAuthUserById(id: $id) {
        userId
        result
      }
    }
  `

  const [usersInviteRunning, setUsersInviteRunning] = useState(false)
  const [modalMessages, setModalMessages] = useState<InviteMessage[]>([])
  const [inviteUserMutation] = useMutation(INVITE_USER_MUTATION, {})

  useEffect(() => {
    const updateInvitedUsers = async () => {
      await invitedUsers(users)
    }
    if (isOpen) {
      updateInvitedUsers()
    }
  }, [isOpen])

  const userInviteErrorMessage = (error: Error) => {
    return error.message?.startsWith('ReInviteUserDoesNotExistException')
      ? 'Has not been invited yet'
      : error.message?.startsWith('ReInviteUnsupportedUserStateException')
        ? 'Already signed-up'
        : error.message?.startsWith('SupportModeCannotSendFirstUserInvite')
          ? 'Support Mode cannot send the initial invite to a new user'
          : error.message?.startsWith('CognitoUserIsExternalProviderError')
            ? 'Cannot invite SSO user'
            : 'An error occurred.'
  }

  const resetInviteMessages = (users: UserData[]) => {
    for (const userData of users) {
      updateUserInviteMessage({
        id: userData.userId,
        type: 'waiting',
        message: userData.name,
      })
    }
  }

  const updateUserInviteMessage = (message: InviteMessage) => {
    setModalMessages((messages) => {
      const index = messages.findIndex((m) => m.id === message.id)
      if (index === -1) {
        return [...messages, message]
      }
      messages[index] = message
      return [...messages]
    })
  }

  const invitedUsers = async (users: UserData[]) => {
    setUsersInviteRunning(true)
    resetInviteMessages(users)
    for (const user of users) {
      let message: InviteMessage
      try {
        const response = await inviteUserMutation({
          variables: {
            id: user.userId,
          },
        })
        message = {
          id: user.userId,
          type: 'success',
          message: `${user.name} - ${response.data.inviteAuthUserById.result}`,
        }
      } catch (error) {
        message = {
          id: user.userId,
          type: 'error',
          message: `${user.name} - ${userInviteErrorMessage(error)}`,
        }
      }
      updateUserInviteMessage(message)
    }
    setUsersInviteRunning(false)
  }

  const errorOccurred = modalMessages?.map((msg) => msg.type).includes('error')

  return (
    <Modal
      open={isOpen}
      title={'Invite Users'}
      dialogClassName={'max-w-[700px]'}
      backDropClickDisable={usersInviteRunning}
      onClose={onClose}
      footerVisible
      confirmText="Continue"
      onConfirm={onClose}
      onCancel={onClose}
    >
      <div className="p-10">
        <div className="w-full max-w-[500px] mx-auto flex flex-col gap-2">
          <p className="border-b px-1 text-gray-400 text-sm pb-1 flex justify-between items-center">
            <span>Status</span>
            <span>User</span>
          </p>
          {modalMessages?.map((message, index) => (
            <div
              key={index}
              className="border-b last-of-type:border-0 p-2 flex gap-2 items-center justify-between"
            >
              {message.type === 'error' ? (
                <XCircleIcon className="text-red-500 h-8 w-8" />
              ) : message.type === 'success' ? (
                <CheckCircleIcon className="text-green-500 h-8 w-8" />
              ) : (
                <ArrowPathIcon className="animate-spin text-gray-400 h-8 w-8" />
              )}
              <p className="normal-case">{message.message}</p>
            </div>
          ))}
          {errorOccurred && (
            <p className="pt-10 text-sm text-center text-gray-400">
              An error occurred while trying to send some invitations. Please
              try the failed invites again, or contact support if you continue
              to experience issues.
            </p>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default InviteModal
