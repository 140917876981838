import dayjs from 'dayjs'
import { GlobalRole } from 'types/graphql'

import { CurrentUser } from '@redwoodjs/auth'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'


import { QUERY as UserDataGridQuery } from 'src/components/MemberManagementCell/MemberManagementCell'
import { useAuth } from 'web/src/Providers'

const ACTIVATE_MEMBER = gql`
  mutation ActivateMemberMutation($id: Int!) {
    activateMembership(id: $id) {
      id
    }
  }
`

export const useActivateMember = () => {
  const { currentUser }: { currentUser: CurrentUser } = useAuth()

  const [activateMemberMutation] = useMutation(ACTIVATE_MEMBER, {
    onCompleted: () => {},
    refetchQueries: [
      {
        query: UserDataGridQuery,
        variables: {
          clientId: currentUser.membershipData.clientId,
        },
      },
    ],
  })

  const activateMember = async (args) => {
    for (const item of args.data) {
      const today = dayjs()
      const lastDeactivatedAt = dayjs(item.deactivatedAt)
      const diff = today.diff(lastDeactivatedAt, 'hours')

      const isSuperAdmin =
        currentUser.userData.role === ('SUPERADMIN' as GlobalRole)

      if (diff >= 24 || isSuperAdmin) {
        await activateMemberMutation({
          variables: {
            id: item.id,
          },
        }).then(() => {
          toast.success('User has been activated.')
        })
      } else {
        toast.error(
          'User cannot be activated because it has been deactivated less than 24 hours ago.',
        )
      }
    }
  }

  return { activateMember }
}
