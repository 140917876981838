import { FC } from 'react'

import { CardContent, Tooltip, Typography, Box } from '@mui/material'
import Card from '@mui/material/Card'

export enum CardColors {
  blue = 'bg-blue-100 text-blue-600 border border-blue-600',
  green = 'bg-green-100 text-green-600 border border-green-600',
  red = 'bg-red-100 text-red-600 border border-red-600',
  yellow = 'bg-yellow-100 text-yellow-600 border border-yellow-600',
  gray = 'bg-gray-100 text-gray-600 border border-gray-600',
  indigo = 'bg-indigo-100 text-indigo-600 border border-indigo-600',
  orange = 'bg-orange-100 text-orange-600 border border-orange-600',
  pink = 'bg-pink-100 text-pink-600 border border-pink-600',
  purple = 'bg-purple-100 text-purple-600 border border-purple-600',
}

interface StatisticsProps {
  title: string | number | JSX.Element
  data: string | number | JSX.Element
  description?: string | number | JSX.Element
  color: CardColors
  icon: JSX.Element
  className?: string
  active?: boolean
  onClick?: () => void
  tooltip?: string
}

const Statistic: FC<StatisticsProps> = ({
  title,
  data,
  className,
  icon,
  color,
  active,
  onClick,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip} followCursor>
      <Card
        sx={{ display: 'flex' }}
        elevation={0}
        variant={'outlined'}
        className={`rounded-lg ${className} cursor-pointer transition-all hover:bg-indigo-50 grow basis-0 ${
          active ? 'ring-indigo-400 ring-2 border-transparent bg-indigo-50' : ''
        }`}
        onClick={() => onClick()}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: ' center',
          }}
        >
          <CardContent
            sx={{ flex: '1 0 auto' }}
            data-testid={`statistic-${title}-${data}`}
          >
            <Typography component="div" variant="h5">
              {data}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {title}
            </Typography>
          </CardContent>
        </Box>
        {icon && (
          <div className={'flex flex-1 justify-end items-center px-6'}>
            <div className={`p-2 rounded-xl ${color}`}>{icon}</div>
          </div>
        )}
      </Card>
    </Tooltip>
  )
}

export default Statistic
