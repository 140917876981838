import React, { useEffect, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/24/solid'
import {
  Box,
  Grid2 as Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { CreateMemberPositionInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import Modal from 'src/components/Modal/Modal'
import { useAuth } from 'src/Providers'

type CreateMemberPositionReturnType = {
  createMemberPosition: {
    id: number
    name: string
  }
}

const InformerOrgChartUpsertSeatModal = ({
  openModal,
  setOpenUpsertPositionModal,
  currentPositions,
  setCurrentPositions,
}) => {
  const [positionName, setPositionName] = useState('')
  const [positionDescription, setPositionDescription] = useState('')
  const [responsibilities, setResponsibilities] = useState([])
  const [measurables, setMeasurables] = useState([])
  const [isGenerating, setIsGenerating] = useState(false)
  const [newResponsibility, setNewResponsibility] = useState('')
  const [newMeasurable, setNewMeasurable] = useState('')

  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  const handleDeleteResponsibility = (index) => {
    const newResponsibilityList = [...responsibilities]
    newResponsibilityList.splice(index, 1)
    setResponsibilities(newResponsibilityList)
  }

  // Function to handle the deletion of a measurable
  const handleDeleteMeasurable = (index) => {
    const newMeasurableList = [...measurables]
    newMeasurableList.splice(index, 1)
    setMeasurables(newMeasurableList)
  }

  // Function to handle the addition of a responsibility
  const handleAddResponsibility = () => {
    if (newResponsibility.trim()) {
      setResponsibilities([...responsibilities, newResponsibility.trim()])
      setNewResponsibility('')
    }
  }

  // Function to handle the addition of a measurable
  const handleAddMeasurable = () => {
    if (newMeasurable.trim()) {
      setMeasurables([...measurables, newMeasurable.trim()])
      setNewMeasurable('')
    }
  }

  const handleInsert = async () => {
    const payload: CreateMemberPositionInput = {
      name: positionName,
      description: positionDescription,
      responsibilities: JSON.stringify(responsibilities),
      measurables: JSON.stringify(measurables),
    }
    await CreateMemberPosition({
      variables: {
        input: payload,
      },
    })
  }

  const CREATE_MEMBER_POSITION = gql`
    mutation CreateMemberPosition($input: CreateMemberPositionInput!) {
      createMemberPosition(input: $input) {
        id
        name
      }
    }
  `

  const [CreateMemberPosition] = useMutation<
    CreateMemberPositionReturnType,
    GraphQLOperationVariables
  >(CREATE_MEMBER_POSITION, {
    onCompleted: (data) => {
      setCurrentPositions([
        {
          label: data.createMemberPosition.name,
          value: data.createMemberPosition.id,
        },
        ...currentPositions,
      ])
      setIsGenerating(false)
      setOpenUpsertPositionModal(false)
      toast.success('Position created successfully')
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
      setIsGenerating(false)
    },
  })

  useEffect(() => {
    if (openModal) {
      setPositionName('')
      setPositionDescription('')
      setResponsibilities([])
      setMeasurables([])
      setNewMeasurable('')
      setNewResponsibility('')
    }
  }, [openModal])

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenUpsertPositionModal(false)}
      title={'Insert New Position'}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 12 }}>
            <Stack padding={'20px'}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 4, sm: 4 }}>
                  <InputLabel>Position Name</InputLabel>
                </Grid>
                <Grid size={{ xs: 8, sm: 8 }}>
                  <TextField
                    type="text"
                    value={positionName}
                    onChange={(e) => setPositionName(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid size={{ xs: 4, sm: 4 }}>
                  <InputLabel>Description</InputLabel>
                </Grid>
                <Grid size={{ xs: 8, sm: 8 }}>
                  <TextField
                    type="text"
                    value={positionDescription}
                    placeholder={'Eg: Smithfield'}
                    onChange={(e) => setPositionDescription(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid
                  size={{ xs: 4, sm: 4 }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <InputLabel>Responsibilities</InputLabel>
                </Grid>
                <Grid size={{ xs: 8, sm: 8 }}>
                  <List component="nav">
                    {responsibilities.map((responsibility, index) => (
                      <ListItem
                        key={index}
                        divider
                        style={{ height: '30px', padding: '8px 0px' }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              style={{ fontSize: '12px' }}
                            >
                              {responsibility}
                            </Typography>
                          }
                        />
                        <Tooltip title={'Delete this responsibility'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="outlined"
                              className="min-w-[0] rounded-full p-1"
                              onClick={() => handleDeleteResponsibility(index)}
                            >
                              <TrashIcon className="h-3 w-3 text-red-600" />
                            </Button>
                          </div>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>

                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <TextField
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      size="small"
                      label="New Responsibility"
                      value={newResponsibility}
                      onChange={(e) => setNewResponsibility(e.target.value)}
                    />
                    <Tooltip title={'Add this responsibility'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="outlined"
                          className="min-w-[0] rounded-full p-1"
                          onClick={() => handleAddResponsibility()}
                        >
                          <PlusIcon className="h-3 w-3" />
                        </Button>
                      </div>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid
                  size={{ xs: 4, sm: 4 }}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <InputLabel>Measurables</InputLabel>
                </Grid>
                <Grid size={{ xs: 8, sm: 8 }}>
                  <List component="nav">
                    {measurables.map((measurable, index) => (
                      <ListItem
                        key={index}
                        divider
                        style={{ height: '30px', padding: '8px 0px' }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              style={{ fontSize: '12px' }}
                            >
                              {measurable}
                            </Typography>
                          }
                        />
                        <Tooltip title={'Delete this measurable'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="outlined"
                              className="min-w-[0] rounded-full p-1"
                              onClick={() => handleDeleteMeasurable(index)}
                            >
                              <TrashIcon className="h-3 w-3 text-red-600" />
                            </Button>
                          </div>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>

                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <TextField
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      size="small"
                      label="New Measurable"
                      value={newMeasurable}
                      onChange={(e) => setNewMeasurable(e.target.value)}
                    />
                    <Tooltip title={'Add this measurable'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="outlined"
                          className="min-w-[0] rounded-full p-1"
                          onClick={() => handleAddMeasurable()}
                        >
                          <PlusIcon className="h-3 w-3" />
                        </Button>
                      </div>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <div className="float-right py-4">
          <Button
            variant="contained"
            onClick={() => {
              setIsGenerating(true)
              handleInsert()
            }}
            loading={isGenerating}
          >
            Insert
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default InformerOrgChartUpsertSeatModal
