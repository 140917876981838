import { FC, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import { ArrowPathIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import {
  ResetUserMFAMutation,
  ResetUserMFAMutationVariables,
} from 'types/graphql'

import { UserData } from 'src/components/MemberManagement/MemberManagement.mapper'
import Modal from 'src/components/Modal/Modal'

interface ResetMfaModalProps {
  isOpen: boolean
  users: UserData[]
  onClose(): void
}

export interface ResetMfaMessage {
  id: number
  type: 'success' | 'error' | 'waiting' | 'SSO'
  message: string
}

const ResetMfaModal: FC<ResetMfaModalProps> = ({ isOpen, users, onClose }) => {
  const RESET_USER_MFA_MUTATION = gql`
    mutation ResetUserMFAMutation($userId: Int!) {
      resetUserMFAById(id: $userId) {
        userId
        result
      }
    }
  `

  const [mfaResetRunning, setUsersMfaResetRunning] = useState(false)
  const [modalMessages, setModalMessages] = useState<ResetMfaMessage[]>([])

  const [resetMFAMutation] = useMutation<
    ResetUserMFAMutation,
    ResetUserMFAMutationVariables
  >(RESET_USER_MFA_MUTATION)

  useEffect(() => {
    users
      .filter((user) => user.cognitoUserStatus === 'EXTERNAL_PROVIDER')
      .map((user) =>
        updateResetMFAMessage({
          id: user.id,
          message: `${user.name} - User has SSO enabled, MFA reset not possible`,
          type: 'SSO',
        }),
      )
  }, [users])

  useEffect(() => {
    const updateResetMFAUsers = async () => {
      await resetMFA(users)
    }

    if (isOpen) {
      updateResetMFAUsers()
    }
  }, [isOpen])

  const resetMFAErrorMessage = (error: Error) => {
    return error.message
  }

  const resetMFAMessages = (users: UserData[]) => {
    for (const userData of users) {
      updateResetMFAMessage({
        id: userData.userId,
        type: 'waiting',
        message: userData.name,
      })
    }
  }

  const updateResetMFAMessage = (message: ResetMfaMessage) => {
    setModalMessages((messages) => {
      const index = messages.findIndex((m) => m.id === message.id)
      if (index === -1) {
        return [...messages, message]
      }
      messages[index] = message
      return [...messages]
    })
  }

  const resetMFA = async (users: UserData[]) => {
    setUsersMfaResetRunning(true)
    const nonSSOUsers = users.filter(
      (user) => user.cognitoUserStatus !== 'EXTERNAL_PROVIDER',
    )
    resetMFAMessages(nonSSOUsers)

    for (const user of nonSSOUsers) {
      let message: ResetMfaMessage
      try {
        const _response = await resetMFAMutation({
          variables: {
            userId: user.userId,
          },
        })

        message = {
          id: user.userId,
          type: 'success',
          message: `${user.name}`,
        }
      } catch (error) {
        message = {
          id: user.userId,
          type: 'error',
          message: `${user.name} - ${resetMFAErrorMessage(error)}`,
        }
      }

      updateResetMFAMessage(message)
    }

    setUsersMfaResetRunning(false)
  }

  const errorOccurred =
    modalMessages?.filter((msg) => msg.type === 'error').length > 0

  return (
    <Modal
      open={isOpen}
      title={'Reset MFA for Users'}
      dialogClassName={'max-w-[700px]'}
      backDropClickDisable={mfaResetRunning}
      onClose={onClose}
      footerVisible
      cancelButtonVisible={false}
      confirmText="Close"
      onConfirm={onClose}
    >
      <div className="p-10">
        <div className="w-full max-w-[500px] mx-auto flex flex-col gap-2">
          <p className="border-b px-1 text-gray-400 text-sm pb-1 flex justify-between items-center">
            <span>Status</span>
            <span>User</span>
          </p>
          {modalMessages?.map((message, index) => (
            <div
              key={index}
              className="border-b last-of-type:border-0 p-2 flex gap-2 items-center justify-between"
            >
              {message.type === 'SSO' ? (
                <LockClosedIcon className="text-yellow-500 h-8 w-8" />
              ) : message.type === 'error' ? (
                <XCircleIcon className="text-red-500 h-8 w-8" />
              ) : message.type === 'success' ? (
                <CheckCircleIcon className="text-green-500 h-8 w-8" />
              ) : (
                <ArrowPathIcon className="animate-spin text-gray-400 h-8 w-8" />
              )}
              <p className="normal-case">{message.message}</p>
            </div>
          ))}
          {errorOccurred && (
            <p className="pt-10 text-sm text-center text-gray-400">
              An error occurred while trying to reset some accounts. Please try
              again, or contact support if you continue to experience issues.
            </p>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ResetMfaModal
